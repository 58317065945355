import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { RiUserAddLine } from 'react-icons/ri';
import TermsModal from '../../common/TermsModal/TermsModal';
import { useAuth } from '../../../context/AuthContext';

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 120px);
  padding: ${({ theme }) => theme.spacing.lg};
`;

const LoginCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.light};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: ${({ theme }) => theme.spacing.xl};
  width: 100%;
  max-width: 400px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme }) => theme.colors.text.primary};
  
  svg {
    color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`;

const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.background.dark};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const Button = styled.button`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.primary.accent};
  color: white;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
    transform: translateY(-2px);
  }
  
  &:active {
    transform: translateY(0);
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.lg};
  margin-top: ${({ theme }) => theme.spacing.lg};
  
  a {
    color: ${({ theme }) => theme.colors.text.secondary};
    text-decoration: none;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    
    &:hover {
      color: ${({ theme }) => theme.colors.primary.accent};
    }
  }
`;

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error || '#ff4d4f'};
  font-size: 0.9rem;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.sm};
  background: ${({ theme }) => theme.colors.error + '10' || '#ff4d4f10'};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

const TermsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  text-align: center;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  
  button {
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.primary.accent};
    cursor: pointer;
    padding: 0 ${({ theme }) => theme.spacing.xs};
    font-size: 0.9rem;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    nickname: ''
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    let response;
    let data;

    try {
      if (formData.password !== formData.confirmPassword) {
        setError('비밀번호가 일치하지 않습니다.');
        setIsLoading(false);
        return;
      }

      response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/local/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: formData.username.trim(),
          email: formData.email.trim(),
          password: formData.password
        }),
      });

      data = await response.json();
      
      if (!response.ok) {
        let errorMessage;
        if (data.error?.message === 'Email or Username are already taken') {
          errorMessage = '이미 사용 중인 이메일 또는 아이디입니다.';
        } else {
          errorMessage = data.error?.message || '회원가입에 실패했습니다.';
        }
        throw new Error(errorMessage);
      }

      await login(data.user);
      console.log('회원가입 성공:', data);
      navigate('/');
      
    } catch (error) {
      console.error('Registration error details:', {
        status: response?.status,
        error: error,
        data: data
      });
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <LoginCard
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Title>
          <RiUserAddLine />
          회원가입
        </Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="아이디"
            value={formData.username}
            onChange={(e) => setFormData({...formData, username: e.target.value})}
            required
          />
          <Input
            type="password"
            placeholder="비밀번호"
            value={formData.password}
            onChange={(e) => setFormData({...formData, password: e.target.value})}
            required
          />
          <Input
            type="password"
            placeholder="비밀번호 확인"
            value={formData.confirmPassword}
            onChange={(e) => setFormData({...formData, confirmPassword: e.target.value})}
            required
          />
          <Input
            type="email"
            placeholder="이메일"
            value={formData.email}
            onChange={(e) => setFormData({...formData, email: e.target.value})}
            required
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Button type="submit" disabled={isLoading}>
            {isLoading ? '처리중...' : '회원가입'}
          </Button>
        </Form>
        
        <TermsContainer>
          회원가입 시{' '}
          <button type="button" onClick={() => setTermsModalOpen(true)}>
            이용약관
          </button>
          과{' '}
          <button type="button" onClick={() => setPrivacyModalOpen(true)}>
            개인정보처리방침
          </button>
          에 동의하는 것으로 간주됩니다.
        </TermsContainer>
      </LoginCard>
      
      <TermsModal 
        isOpen={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
        type="terms"
      />
      <TermsModal 
        isOpen={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
        type="privacy"
      />
    </Container>
  );
};

export default Register;
