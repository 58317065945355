import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Loading from '../../common/Loading';
import { useApp } from '../../../context/AppContext';
import { RiNewspaperLine } from 'react-icons/ri';
import PostModal from '../../common/PostModal/PostModal';
import { FaUser } from 'react-icons/fa';

const Container = styled(motion.div)`
  padding: ${({ theme }) => theme.spacing.lg};
`;

const NewsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.spacing.lg};
  margin-top: ${({ theme }) => theme.spacing.lg};
`;

const NewsCard = styled(motion.article)`
  background: ${({ theme }) => theme.colors.background.light};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  overflow: hidden;
  
  .image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }
  }
  
  &:hover img {
    transform: scale(1.1);
  }
  
  .content {
    padding: ${({ theme }) => theme.spacing.md};
    
    p {  // summary를 위한 스타일
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${({ theme }) => theme.colors.text.secondary};
      font-size: 0.9rem;
      line-height: 1.5;
      height: 4rem;  // 대략 3줄 높이
      margin-top: ${({ theme }) => theme.spacing.sm};
    }
  }
  
  .meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing.sm};
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: 0.9em;
  }
  
  .author {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
    
    svg {
      font-size: 1.1em;
    }
  }
`;

const SubHeader = styled(motion.p)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  max-width: 800px;

  strong {
    color: ${({ theme }) => theme.colors.primary.accent};
    font-weight: bold;
  }
`;

const PageTitle = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  h1 {
    margin: 0;
  }

  svg {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const formatContent = (content) => {
  if (!content || !Array.isArray(content)) return '';
  
  return content.map(block => {
    if (block.type === 'paragraph') {
      return block.children
        .map(child => {
          if (child.bold) {
            return `**${child.text}**`;
          }
          return child.text;
        })
        .join(' ');
    }
    return '';
  }).join('\n');
};

const News = () => {
  const { state, actions } = useApp();
  const [loading, setLoading] = useState(true);
  const [selectedNews, setSelectedNews] = useState(null);
  const [newsData, setNewsData] = useState([]);
  
  const { searchTerm, page } = state.news;
  
  useEffect(() => {
    const fetchNews = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/news-collections?populate=*`);
        
        const data = await response.json();
        
        console.log('Raw API Response:', data);

        const formattedNews = data.data.map(item => {
          console.log('Processing item:', item);
          
          return {
            id: item.id,
            documentId: item.documentId,
            title: item.title || '',
            content: item.content || [],
            summary: item.summary || '',
            date: item.date || '',
            views: item.views || 0,
            likes: item.likes || 0,
            category: item.category || '',
            author: item.users?.nickname || item.users?.username || '익명',
            image: item.image?.[0]?.url
              ? `${process.env.REACT_APP_API_URL}${item.image[0].url}`
              : null
          };
        });

        setNewsData(formattedNews);
      } catch (error) {
        console.error('뉴스 데이터 로딩 실패:', error);
        console.error('Error details:', error.message);
        actions.addNotification({
          type: 'error',
          message: '뉴스를 불러오는데 실패했습니다.'
        });
      } finally {
        setLoading(false);
      }
    };
    
    fetchNews();
  }, [page]);

  if (loading) return <Loading />;

  const filteredNews = newsData;

  const handleNewsClick = (news) => {
    setSelectedNews(news);
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <PageTitle
        initial={{ y: -20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <RiNewspaperLine />
        <h1>스포츠 뉴스</h1>
      </PageTitle>
      
      <SubHeader
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ 
          duration: 0.6, 
          delay: 1,
          ease: "easeOut" 
        }}
      >
        실시간으로 업데이트되는 각종 <strong>스포츠 소식 !</strong>
      </SubHeader>

      <NewsGrid>
        {filteredNews.map((news, index) => (
          <NewsCard
            key={news.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.4,
              delay: index * 0.1 + 2.7,
              ease: "easeOut"
            }}
            whileHover={{ y: -5 }}
            onClick={() => handleNewsClick(news)}
          >
            <div className="image-container">
              <img src={news.image} alt={news.title} />
            </div>
            <div className="content">
              <h3>{news.title}</h3>
              <p>{news.summary}</p>
              <div className="meta">
                <span className="date">{news.date}</span>
              </div>
            </div>
          </NewsCard>
        ))}
      </NewsGrid>

      <PostModal 
        post={selectedNews} 
        onClose={() => setSelectedNews(null)}
      />
    </Container>
  );
};

export default News;
