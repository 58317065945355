import { createContext, useContext, useReducer } from 'react';

// 초기 상태 정의
const initialState = {
  user: null,
  favorites: [],
  notifications: [],
  settings: {
    refreshInterval: 60000,
    showNotifications: true,
    theme: 'dark'
  },
  sports: {
    activeCategory: 'all',
    filters: {
      league: 'all',
      date: 'today'
    }
  },
  odds: {
    selectedBookmakers: [],
    sortBy: 'default'
  },
  news: {
    category: 'all',
    searchTerm: '',
    page: 1
  },
  events: {
    participatedEvents: [],
    activeTab: 'ongoing'
  }
};

// 액션 타입 정의
export const ACTIONS = {
  SET_USER: 'SET_USER',
  TOGGLE_FAVORITE: 'TOGGLE_FAVORITE',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  SET_SPORTS_FILTER: 'SET_SPORTS_FILTER',
  SET_ODDS_FILTER: 'SET_ODDS_FILTER',
  SET_NEWS_FILTER: 'SET_NEWS_FILTER',
  PARTICIPATE_EVENT: 'PARTICIPATE_EVENT',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  CLEAR_NOTIFICATION: 'CLEAR_NOTIFICATION'
};

// 리듀서 함수
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_USER:
      return { ...state, user: action.payload };
      
    case ACTIONS.TOGGLE_FAVORITE:
      const exists = state.favorites.some(fav => fav.id === action.payload.id);
      return {
        ...state,
        favorites: exists
          ? state.favorites.filter(fav => fav.id !== action.payload.id)
          : [...state.favorites, action.payload]
      };
      
    case ACTIONS.UPDATE_SETTINGS:
      return {
        ...state,
        settings: { ...state.settings, ...action.payload }
      };
      
    case ACTIONS.SET_SPORTS_FILTER:
      return {
        ...state,
        sports: { ...state.sports, ...action.payload }
      };
      
    case ACTIONS.SET_ODDS_FILTER:
      return {
        ...state,
        odds: { ...state.odds, ...action.payload }
      };
      
    case ACTIONS.SET_NEWS_FILTER:
      return {
        ...state,
        news: { ...state.news, ...action.payload }
      };
      
    case ACTIONS.PARTICIPATE_EVENT:
      return {
        ...state,
        events: {
          ...state.events,
          participatedEvents: [...state.events.participatedEvents, action.payload]
        }
      };
      
    case ACTIONS.ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications]
      };
      
    case ACTIONS.CLEAR_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(n => n.id !== action.payload)
      };
      
    default:
      return state;
  }
};

// Context 생성
const AppContext = createContext();

// Provider 컴포넌트
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // 유용한 액션 생성자 함수들
  const actions = {
    setUser: (user) => dispatch({ type: ACTIONS.SET_USER, payload: user }),
    toggleFavorite: (item) => dispatch({ type: ACTIONS.TOGGLE_FAVORITE, payload: item }),
    updateSettings: (settings) => dispatch({ type: ACTIONS.UPDATE_SETTINGS, payload: settings }),
    setSportsFilter: (filters) => dispatch({ type: ACTIONS.SET_SPORTS_FILTER, payload: filters }),
    setOddsFilter: (filters) => dispatch({ type: ACTIONS.SET_ODDS_FILTER, payload: filters }),
    setNewsFilter: (filters) => dispatch({ type: ACTIONS.SET_NEWS_FILTER, payload: filters }),
    participateEvent: (eventId) => dispatch({ type: ACTIONS.PARTICIPATE_EVENT, payload: eventId }),
    addNotification: (notification) => dispatch({ type: ACTIONS.ADD_NOTIFICATION, payload: notification }),
    clearNotification: (id) => dispatch({ type: ACTIONS.CLEAR_NOTIFICATION, payload: id })
  };

  return (
    <AppContext.Provider value={{ state, dispatch, actions }}>
      {children}
    </AppContext.Provider>
  );
};

// 커스텀 훅
export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within an AppProvider');
  }
  return context;
};
