import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { RiLoginBoxLine } from 'react-icons/ri';

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 120px);
  padding: ${({ theme }) => theme.spacing.lg};
`;

const LoginCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.light};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: ${({ theme }) => theme.spacing.xl};
  width: 100%;
  max-width: 400px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme }) => theme.colors.text.primary};
  
  svg {
    color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`;

const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.background.dark};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const Button = styled.button`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.primary.accent};
  color: white;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
    transform: translateY(-2px);
  }
  
  &:active {
    transform: translateY(0);
  }
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.lg};
  margin-top: ${({ theme }) => theme.spacing.lg};
  
  a {
    color: ${({ theme }) => theme.colors.text.secondary};
    text-decoration: none;
    font-size: 0.9rem;
    transition: all 0.2s ease;
    
    &:hover {
      color: ${({ theme }) => theme.colors.primary.accent};
    }
  }
`;

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/local`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          identifier: username,
          password,
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        const errorMessage = data.error?.message === 'Invalid identifier or password'
          ? 'ID 혹은 비밀번호가 잘못됐습니다.'
          : data.error?.message || '로그인에 실패했습니다.';
        throw new Error(errorMessage);
      }

      console.log('Strapi 응답 데이터:', data);
      
      // AuthContext의 login 함수 호출
      await login(data.jwt, data.user);
      navigate('/');
      
    } catch (error) {
      console.error('로그인 실패:', error);
      // Failed to fetch 에러 체크 및 커스텀 메시지 설정
      const errorMessage = error.message === 'Failed to fetch' 
        ? '로그인 서버 연결 실패.\n관리자에게 문의해주세요'
        : error.message;
      setError(errorMessage);
    }
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <LoginCard
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Title>
          <RiLoginBoxLine />
          로그인
        </Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder="아이디"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            type="password"
            placeholder="비밀번호"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <ErrorMessage>
              {error}
            </ErrorMessage>
          )}
          <Button type="submit">로그인</Button>
        </Form>
        <LinkContainer>
          <Link to="/register">회원가입</Link>
          <Link to="/forgot-password">비밀번호 찾기</Link>
        </LinkContainer>
      </LoginCard>
    </Container>
  );
};

const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error || '#ff4d4f'};
  font-size: 0.9rem;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.sm};
  background: ${({ theme }) => theme.colors.error + '10' || '#ff4d4f10'};
  border-radius: ${({ theme }) => theme.borderRadius.small};
`;

export default Login;
