import { useState } from 'react';
import { motion } from 'framer-motion';
import { 
  RiTimer2Line,      // 실시간 스코어용
  RiTeamLine,        // 라인업용
  RiUserUnfollowLine,// 결장자용
  RiExchangeLine,     // 배당률용
  RiFootballLine     // 축구 통계용 아이콘 추가
} from 'react-icons/ri';
import { IframeContainer } from '../../common/IframeContainer';
import { Container, TabsContainer, Tab, ContentCard } from './LineupInfo.styles';

const LineupInfo = () => {
  const [activeTab, setActiveTab] = useState('score');
  
  const tabs = {
    score: 'https://sports.named.com/soccer',
    lineup: 'https://www.rotowire.com/soccer/lineups.php',
    injury: 'https://www.rotowire.com/soccer/injury-report.php',
    odds: 'https://www.oddsportal.com/matches/football/'
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <TabsContainer>
        <Tab
          onClick={() => setActiveTab('score')}
          className={activeTab === 'score' ? 'active' : ''}
        >
          <RiTimer2Line />
          <span>실시간 스코어</span>
        </Tab>
        <Tab
          onClick={() => setActiveTab('lineup')}
          className={activeTab === 'lineup' ? 'active' : ''}
        >
          <RiTeamLine />
          라인업
        </Tab>
        <Tab
          onClick={() => setActiveTab('injury')}
          className={activeTab === 'injury' ? 'active' : ''}
        >
          <RiUserUnfollowLine />
          결장자
        </Tab>
        <Tab
          onClick={() => setActiveTab('odds')}
          className={activeTab === 'odds' ? 'active' : ''}
        >
          <RiExchangeLine />
          배당률 비교
        </Tab>
      </TabsContainer>
      
      <ContentCard
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <IframeContainer url={tabs[activeTab]} />
      </ContentCard>
    </Container>
  );
};

export default LineupInfo;
