import { useApp } from '../../../context/AppContext';
import Card from '../../common/Card/Card';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { RiTimeLine, RiNewspaperLine, RiFireLine, RiMedalLine, RiLiveLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import { useState, useEffect } from 'react';
import Slider from 'react-slick';

const HomeContainer = styled(motion.div)`
  padding: ${({ theme }) => theme.spacing.md};
  width: 100%;
`;

const GameList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.spacing.md};
`;

const CarouselSection = styled.section`
  margin: ${({ theme }) => theme.spacing.xl} 0;
  
  & + & {
    margin-top: 3rem;
  }
`;

const CarouselHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  padding: 0 ${({ theme }) => theme.spacing.md};
`;

const Title = styled.h2`
  font-size: 1.5rem;
  color: #fff;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.text.accent};
  }

  svg {
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.text.accent};
  }
`;

const ViewMore = styled.button`
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text.secondary};
  cursor: pointer;
  font-size: 0.875rem;
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.text.accent};
  }
`;

const CarouselContainer = styled.div`
  margin: 0;
  width: 100%;

  .slick-track {
    display: flex !important;
    gap: ${({ theme }) => theme.spacing.xl};
    margin-left: 0;
  }

  .slick-slide {
    padding: 0;
    height: auto;
  }

  .slick-list {
    overflow: visible;
    margin: 0;
  }
`;

const GameCard = styled.div`
  width: 210px !important;
  height: 300px !important;
  background: ${({ theme }) => theme.colors.background.light};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  cursor: pointer;
  transition: all 0.2s ease;
  color: ${({ theme }) => theme.colors.text.primary};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: visible;
  display: flex;
  flex-direction: column;
  margin: 4px 0;

  @media (max-width: 1440px) {
    width: 200px !important;
  }

  @media (max-width: 1200px) {
    width: 190px !important;
  }

  @media (max-width: 900px) {
    width: 180px !important;
  }

  @media (max-width: 600px) {
    width: 170px !important;
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    border-color: ${({ theme }) => theme.colors.border.accent};
  }
`;

const Thumbnail = styled.div`
  width: 100%;
  height: 110px;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  background-color: ${({ theme }) => theme.colors.background.dark};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.medium};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.medium};
`;

const CardContent = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  padding-bottom: ${({ theme }) => theme.spacing.xl};
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;

const LeagueInfo = styled.div`
  font-size: 0.875rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text.primary};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  min-height: 2.5em;
  line-height: 1.2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Teams = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: ${({ theme }) => theme.spacing.sm} 0;
  border-top: 1px solid ${({ theme }) => theme.colors.border.main};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  min-height: 80px;
`;

const Team = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
    font-size: 0.95rem;
    color: ${({ theme }) => theme.colors.text.secondary};
  }
`;

const GameTime = styled.div`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: right;
  white-space: nowrap;
  margin-top: -${({ theme }) => theme.spacing.md};
`;

const TeamIcon = styled.div`
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.spacing.sm};
`;

const PostThumbnail = styled.img`
  width: 200px;
  height: 120px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  object-fit: cover;
`;

const PostItem = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.lg};
  padding: ${({ theme }) => theme.spacing.lg};
  background: ${({ theme }) => theme.colors.background.light};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;

  &:hover {
    transform: translateX(4px);
    background: ${({ theme }) => theme.colors.background.main};
    border-color: ${({ theme }) => theme.colors.text.accent};
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.md};
    
    ${PostThumbnail} {
      width: 100%;
      height: 200px;
    }
  }
`;

const PostContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;

const PostTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const PostDescription = styled.p`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.text.secondary};
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PostMeta = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.8rem;
  margin-top: auto;
`;

const MetaItem = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
`;

const PostSection = styled.section`
  margin: ${({ theme }) => theme.spacing.xl} 0;
  margin-top: 3rem;
`;

const PostHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  padding: 0 ${({ theme }) => theme.spacing.md};
`;

const PostList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;

const TeamLogo = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: ${({ theme }) => theme.spacing.sm};
`;

const DEFAULT_TEAM_LOGO = '/images/default-team-logo.svg';

const Home = () => {
  const navigate = useNavigate();
  const [highlights, setHighlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newsData, setNewsData] = useState([]);
  const [liveGames, setLiveGames] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const [highlightsResponse, teamsResponse, liveGamesResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/api/highlights?populate=*`),
          fetch(`${process.env.REACT_APP_API_URL}/api/soccer-teams?populate=*&pagination[pageSize]=1000`),
          fetch(`${process.env.REACT_APP_API_URL}/api/live-games?populate=*`)
        ]);

        const [highlightsData, teamsData, liveGamesData] = await Promise.all([
          highlightsResponse.json(),
          teamsResponse.json(),
          liveGamesResponse.json()
        ]);

        console.log('All Teams:', teamsData.data);

        const teamLogos = teamsData.data.reduce((acc, team) => {
          if (team.teamlogo?.url) {
            acc[team.teamname] = `${process.env.REACT_APP_API_URL}${team.teamlogo.url}`;
          }
          return acc;
        }, {});

        console.log('Team Logos:', teamLogos);

        const formattedHighlights = highlightsData.data.map(item => {
          console.log('Home Team:', item.hometeam);
          console.log('Away Team:', item.awayteam);
          
          return {
            id: item.id,
            title: item.Title,
            documentId: item.documentId,
            tags: item.Tags,
            views: item.Views,
            youtubeUrl: item.YouTubeURL,
            thumbnail: item.Thumbnail?.formats?.large?.url 
              ? `${process.env.REACT_APP_API_URL}${item.Thumbnail.url}`
              : "images/dummyThumbnail.avif",
            homeTeam: {
              name: item.hometeam?.teamname || '홈팀 정보 없음',
              logo: teamLogos[item.hometeam?.teamname] || DEFAULT_TEAM_LOGO
            },
            awayTeam: {
              name: item.awayteam?.teamname || '원정팀 정보 없음',
              logo: teamLogos[item.awayteam?.teamname] || DEFAULT_TEAM_LOGO
            }
          };
        });

        const formattedLiveGames = liveGamesData.data.map(game => ({
          id: game.id,
          league: game.matchInfo,
          homeTeam: {
            name: game.hometeam?.teamname || '홈팀 정보 없음',
            logo: teamLogos[game.hometeam?.teamname] || DEFAULT_TEAM_LOGO
          },
          awayTeam: {
            name: game.awayteam?.teamname || '원정팀 정보 없음',
            logo: teamLogos[game.awayteam?.teamname] || DEFAULT_TEAM_LOGO
          },
          status: "UPCOMING",
          time: new Date(game.startTime).toLocaleString('ko-KR', {
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
          }),
          venue: "시작전",
          thumbnail: game.thumbnail?.url 
            ? `${process.env.REACT_APP_API_URL}${game.thumbnail.url}`
            : "images/dummyThumbnail.avif"
        }));

        setHighlights(formattedHighlights);
        setLiveGames(formattedLiveGames);
      } catch (error) {
        console.error('하이라이트 데이터 로딩 실패:', error);
        setHighlights([]);
      } finally {
        setLoading(false);
      }
    };

    const fetchNews = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/news-collections?populate=*`);
        const data = await response.json();

        const formattedNews = data.data.map(item => ({
          id: item.id,
          title: item.title || '',
          content: item.summary || '',
          thumbnail: item.image?.[0]?.url
            ? `${process.env.REACT_APP_API_URL}${item.image[0].url}`
            : 'images/dummyThumbnail.avif',
          views: item.views || 0,
          likes: item.likes || 0,
          author: item.users?.nickname || item.users?.username || '익명',
          date: item.date || new Date().toLocaleDateString()
        }));

        setNewsData(formattedNews);
      } catch (error) {
        console.error('뉴스 데이터 로딩 실패:', error);
        setNewsData([]);
      }
    };

    Promise.all([fetchData(), fetchNews()]);
  }, []);

  if (loading) return <div>로딩 중...</div>;

  const games = [
    {
      id: 1,
      league: "필리핀:COMMISSIONERS CUP",
      homeTeam: { name: "Converge FiberXers", score: 58 },
      awayTeam: { name: "Terrafirma Dyip", score: 42 },
      status: "LIVE",
      time: "51분 경과",
      totalTime: "77분",
      thumbnail: "images/dummyThumbnail.avif"
    },
    {
      id: 2,
      league: "V리그",
      homeTeam: { name: "정관장" },
      awayTeam: { name: "페퍼저축은행" },
      status: "UPCOMING",
      time: "11/27(수) 19:00",
      venue: "시작전",
      thumbnail: "images/dummyThumbnail.avif"
    },
    {
      id: 3,
      league: "KBL",
      homeTeam: { name: "부산 KCC", score: 78 },
      awayTeam: { name: "울산 현대모비스", score: 82 },
      status: "LIVE",
      time: "3쿼터 종료",
      totalTime: "4쿼터",
      thumbnail: "images/dummyThumbnail.avif"
    },
    {
      id: 4,
      league: "ACL ELITE",
      homeTeam: { name: "오코하마 F 마리노스" },
      awayTeam: { name: "포항 스틸러스" },
      status: "UPCOMING",
      time: "11/27(수) 19:00",
      venue: "시작전",
      thumbnail: "images/dummyThumbnail.avif"
    },
    {
      id: 5,
      league: "K리그1",
      homeTeam: { name: "전북현대", score: 2 },
      awayTeam: { name: "울산현대", score: 1 },
      status: "LIVE",
      time: "75분",
      totalTime: "90분",
      thumbnail: "images/dummyThumbnail.avif"
    },
    {
      id: 6,
      league: "NBA",
      homeTeam: { name: "LA Lakers" },
      awayTeam: { name: "Golden State Warriors" },
      status: "UPCOMING",
      time: "11/28(목) 11:00",
      venue: "시작전",
      thumbnail: "images/dummyThumbnail.avif"
    },
    {
      id: 7,
      league: "프리미어리그",
      homeTeam: { name: "맨체스터 시티", score: 3 },
      awayTeam: { name: "리버풀", score: 3 },
      status: "LIVE",
      time: "82분",
      totalTime: "90분",
      thumbnail: "images/dummyThumbnail.avif"
    },
    {
      id: 8,
      league: "V그",
      homeTeam: { name: "대한항공" },
      awayTeam: { name: "우리카드" },
      status: "UPCOMING",
      time: "11/27(수) 20:00",
      venue: "시작전",
      thumbnail: "images/dummyThumbnail.avif"
    },
    {
      id: 9,
      league: "라리가",
      homeTeam: { name: "레 마드리드", score: 0 },
      awayTeam: { name: "바르셀나", score: 0 },
      status: "LIVE",
      time: "15분",
      totalTime: "90분",
      thumbnail: "images/dummyThumbnail.avif"
    },
    {
      id: 10,
      league: "분데스리가",
      homeTeam: { name: "바이에른 뮌헨" },
      awayTeam: { name: "도르트문트" },
      status: "UPCOMING",
      time: "11/28(목) 04:30",
      venue: "시전",
      thumbnail: "images/dummyThumbnail.avif"
    }
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 5,
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.2,
          centerMode: false,
          centerPadding: '0',
          autoplay: false,
        }
      }
    ]
  };

  const goToLive = () => navigate('/live-tv');
  const goToHighlights = () => navigate('/highlights');
  const goToNews = () => navigate(ROUTES.NEWS);

  return (
    <HomeContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <CarouselSection>
        <CarouselHeader>
          <Title onClick={goToLive} style={{ cursor: 'pointer' }}>
            <RiLiveLine />
            오늘의 HOT 중계
          </Title>
          <ViewMore onClick={goToLive}>더보기</ViewMore>
        </CarouselHeader>
        <CarouselContainer>
          <Slider {...sliderSettings}>
            {liveGames.map((game) => (
              <GameCard key={game.id} onClick={goToLive}>
                <Thumbnail src={game.thumbnail} />
                <CardContent>
                  <LeagueInfo>{game.league}</LeagueInfo>
                  <Teams>
                    <Team>
                      {game.homeTeam.logo ? (
                        <TeamLogo src={game.homeTeam.logo} alt={game.homeTeam.name} />
                      ) : (
                        <TeamIcon />
                      )}
                      <span>{game.homeTeam.name}</span>
                    </Team>
                    <Team>
                      {game.awayTeam.logo ? (
                        <TeamLogo src={game.awayTeam.logo} alt={game.awayTeam.name} />
                      ) : (
                        <TeamIcon />
                      )}
                      <span>{game.awayTeam.name}</span>
                    </Team>
                  </Teams>
                  <GameTime>
                    {game.time} • {game.venue}
                  </GameTime>
                </CardContent>
              </GameCard>
            ))}
          </Slider>
        </CarouselContainer>
      </CarouselSection>

      <CarouselSection>
        <CarouselHeader>
          <Title onClick={goToHighlights} style={{ cursor: 'pointer' }}>
            <RiFireLine />
            하이라이트
          </Title>
          <ViewMore onClick={goToHighlights}>더보기</ViewMore>
        </CarouselHeader>
        <CarouselContainer>
          <Slider {...sliderSettings}>
            {highlights.map((highlight) => (
              <GameCard key={highlight.id} onClick={goToHighlights}>
                <Thumbnail src={highlight.thumbnail} />
                <CardContent>
                  <LeagueInfo>{highlight.title}</LeagueInfo>
                  <Teams>
                    {highlight.homeTeam && (
                      <Team>
                        {highlight.homeTeam.logo ? (
                          <TeamLogo src={highlight.homeTeam.logo} alt={highlight.homeTeam.name} />
                        ) : (
                          <TeamIcon />
                        )}
                        <span>{highlight.homeTeam.name || '홈팀'}</span>
                      </Team>
                    )}
                    {highlight.awayTeam && (
                      <Team>
                        {highlight.awayTeam.logo ? (
                          <TeamLogo src={highlight.awayTeam.logo} alt={highlight.awayTeam.name} />
                        ) : (
                          <TeamIcon />
                        )}
                        <span>{highlight.awayTeam.name || '원정팀'}</span>
                      </Team>
                    )}
                  </Teams>
                  <GameTime>{highlight.views.toLocaleString()} views</GameTime>
                </CardContent>
              </GameCard>
            ))}
          </Slider>
        </CarouselContainer>
      </CarouselSection>

      <PostSection>
        <PostHeader>
          <Title onClick={goToNews} style={{ cursor: 'pointer' }}>
            <RiNewspaperLine />
            분석 Zone
          </Title>
          <ViewMore onClick={goToNews}>더보기</ViewMore>
        </PostHeader>
        <PostList>
          {newsData.slice(0, 5).map((post) => (
            <PostItem 
              key={post.id} 
              onClick={() => navigate(ROUTES.NEWS)}
            >
              <PostThumbnail src={post.thumbnail} alt="" />
              <PostContent>
                <PostTitle>{post.title}</PostTitle>
                <PostDescription>{post.content}</PostDescription>
                <PostMeta>
                  <MetaItem>
                    <span>{post.author}</span>
                  </MetaItem>
                  <MetaItem>
                    <span>{post.date}</span>
                  </MetaItem>
                  <MetaItem>
                    <span>조회 {post.views}</span>
                  </MetaItem>
                  <MetaItem>
                    <span>추천 {post.likes}</span>
                  </MetaItem>
                </PostMeta>
              </PostContent>
            </PostItem>
          ))}
        </PostList>
      </PostSection>
    </HomeContainer>
  );
};

export default Home;
