import styled, { keyframes } from 'styled-components';

const fadeInScale = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const progressAnimation = keyframes`
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
`;

export const SplashContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.background.dark};
`;

export const LogoContainer = styled.div`
  text-align: center;
  animation: ${fadeInScale} 0.5s ease forwards;
`;

export const Logo = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

export const LogoText = styled.span`
  font-family: 'Paperlogy-8ExtraBold', sans-serif;
  font-size: 4rem;
  color: ${({ theme }) => theme.colors.text.primary};
  
  &.accent {
    color: ${({ theme }) => theme.colors.primary.accent};
    text-shadow: 0 0 10px ${({ theme }) => theme.colors.primary.accent};
  }
`;

export const SubTitle = styled.p`
  color: ${({ theme }) => theme.colors.text.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

export const LoadingBar = styled.div`
  width: 200px;
  height: 4px;
  background: ${({ theme }) => theme.colors.background.light};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  overflow: hidden;
  margin: 0 auto;
`;

export const LoadingProgress = styled.div`
  width: 0;
  height: 100%;
  background: ${({ theme }) => theme.colors.primary.accent};
  animation: ${progressAnimation} 2s ease forwards;
`;
