import { useState, useEffect } from 'react';
import { useApp } from '../../../context/AppContext';
import Loading from '../../common/Loading';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { RiGiftLine } from 'react-icons/ri';
import PostModal from '../../common/PostModal/PostModal';

const Container = styled(motion.div)`
  padding: ${({ theme }) => theme.spacing.lg};
`;

const EventCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.light};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing.lg};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  transition: ${({ theme }) => theme.animation.transition};
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(252, 163, 17, 0.2);
    border-color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const EventHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.sm};
  }

  h3 {
    font-size: 1.2rem;
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }
`;

const EventDate = styled.span`
  color: ${({ theme }) => theme.colors.accent};
  font-weight: bold;
`;

const EventContent = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};

  p {
    font-size: 1rem;
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`;

const ParticipateButton = styled(motion.button)`
  background: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.text.primary};
  border: none;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  cursor: pointer;
  margin-top: ${({ theme }) => theme.spacing.md};
  transition: all 0.3s ease;
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.accent};
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(252, 163, 17, 0.2);
  }
`;

const SubHeader = styled(motion.p)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  max-width: 800px;

  strong {
    color: ${({ theme }) => theme.colors.primary.accent};
    font-weight: bold;
  }
`;

const EventList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spacing.lg};
  margin-top: ${({ theme }) => theme.spacing.xl};
  max-width: ${({ theme }) => theme.layout.maxWidth};
  margin-left: auto;
  margin-right: auto;
  
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }
`;

const EventType = styled.span`
  color: ${({ theme }) => theme.colors.primary.accent};
  margin-right: ${({ theme }) => theme.spacing.md};
`;

const StatusBadge = styled.span`
  background: ${({ theme }) => theme.colors.primary.accent};
  color: ${({ theme }) => theme.colors.primary.white};
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  font-size: 0.9rem;
`;

const RewardText = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.text.secondary};
  
  strong {
    color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const EventImage = styled.div`
  width: 100%;
  position: relative;
  padding-top: 62.5%;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing.md};
  border: 1px solid ${({ theme }) => theme.colors.border.main};

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: ${({ theme }) => theme.animation.transition};
  }

  &:hover img {
    transform: scale(1.05);
  }
`;

const CategoryTabs = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  overflow-x: auto;
  padding-bottom: ${({ theme }) => theme.spacing.sm};
`;

const CategoryButton = styled(motion.button)`
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primary.accent};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  color: ${({ theme }) => theme.colors.text.primary};
  cursor: pointer;
  
  &.active {
    background: ${({ theme }) => theme.colors.primary.accent};
    color: ${({ theme }) => theme.colors.primary.white};
  }
`;

const PageTitle = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  h1 {
    margin: 0;
  }

  svg {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const Events = () => {
  const { state, actions } = useApp();
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('regular');
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  
  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/events?populate=*${
            activeTab === 'daily' ? '&filters[type][$eq]=daily' : '&filters[type][$eq]=regular'
          }`
        );
        
        const data = await response.json();
        
        if (!data.data) {
          throw new Error('데이터 형식이 올바르지 않습니다.');
        }

        const formattedEvents = data.data.map(event => ({
          id: event.id,
          documentId: event.documentId,
          title: event.Title,
          description: event.description,
          reward: event.reward,
          type: event.type,
          dayOfWeek: event.dayOfWeek,
          status: event.eventstatus,
          content: event.content,
          thumbnail: event.thumbnail?.formats?.medium?.url 
            ? `${process.env.REACT_APP_API_URL}${event.thumbnail.formats.medium.url}`
            : '/images/event-placeholder.png',
          createdAt: new Date(event.createdAt).toLocaleDateString()
        }));

        setEvents(formattedEvents);
        
      } catch (error) {
        console.error('이벤트 데이터 로딩 실패:', error);
        actions.addNotification({
          id: Date.now(),
          type: 'error',
          message: '이벤트 정보를 불러오는데 실패했습니다.'
        });
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [activeTab]);

  const handleParticipate = async (eventId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/event-participations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        },
        body: JSON.stringify({
          data: {
            event: eventId,
            user: state.user.id
          }
        })
      });

      if (!response.ok) {
        throw new Error('이벤트 참여에 실패했습니다');
      }

      actions.participateEvent(eventId);
      actions.addNotification({
        id: Date.now(),
        type: 'success',
        message: '이벤트 참여가 완료되었습니다.'
      });
    } catch (error) {
      console.error('이벤트 참여 실패:', error);
      actions.addNotification({
        id: Date.now(),
        type: 'error',
        message: error.message
      });
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const isParticipated = (eventId) => {
    return events.some(event => event.id === eventId);
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
  };

  if (loading) return <Loading />;

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <PageTitle
        initial={{ y: -20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <RiGiftLine />
        <h1>이벤트</h1>
      </PageTitle>
      
      <SubHeader
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 1 }}
      >
        매일마다 쏟아지는 <strong>개혜자 EVENT !</strong>
      </SubHeader>

      <CategoryTabs>
        <CategoryButton
          className={activeTab === 'regular' ? 'active' : ''}
          onClick={() => setActiveTab('regular')}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, delay: 1.7 }}
        >
          상시 이벤트
        </CategoryButton>
        <CategoryButton
          className={activeTab === 'daily' ? 'active' : ''}
          onClick={() => setActiveTab('daily')}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3, delay: 1.85 }}
        >
          요일별 이벤트
        </CategoryButton>
      </CategoryTabs>

      <EventList>
        {events.map((event, index) => (
          <EventCard
            key={event.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.4,
              delay: index * 0.5 + 1.5,
              ease: "easeOut"
            }}
            onClick={() => handleEventClick(event)}
          >
            <EventImage>
              <img 
                src={event.thumbnail} 
                alt={event.title}
                loading="lazy"
              />
            </EventImage>
            
            <EventHeader>
              <h3>{event.title}</h3>
            </EventHeader>

            <EventContent>
              <p>{event.description}</p>
              <RewardText>
                <strong>보상: {event.reward}</strong>
              </RewardText>
            </EventContent>
          </EventCard>
        ))}
      </EventList>

      {selectedEvent && (
        <PostModal
          post={{
            title: selectedEvent.title,
            content: selectedEvent.content,
            image: selectedEvent.thumbnail,
            date: selectedEvent.createdAt,
            views: 0,
            likes: 0
          }}
          onClose={handleCloseModal}
        />
      )}
    </Container>
  );
};

export default Events;
