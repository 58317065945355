import { motion } from 'framer-motion';
import styled from 'styled-components';
import { RiVideoLine, RiEyeLine } from 'react-icons/ri';
import { useState, useEffect } from 'react';
import PostModal from '../../../components/common/PostModal/PostModal';

const Container = styled(motion.div)`
  padding: ${({ theme }) => theme.spacing.lg};
  max-width: ${({ theme }) => theme.layout.maxWidth};
  margin: 0 auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`;

const PageTitle = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  h1 {
    margin: 0;
  }

  svg {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const SubHeader = styled(motion.p)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 1.1rem;
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  strong {
    color: ${({ theme }) => theme.colors.primary.accent};
    font-weight: bold;
  }
`;

const HighlightsGrid = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacing.lg};
  margin-top: ${({ theme }) => theme.spacing.lg};
  max-width: ${({ theme }) => theme.layout.maxWidth};
  margin-left: auto;
  margin-right: auto;
  
  // 데스크탑: 3열
  grid-template-columns: repeat(3, 1fr);

  // 태블릿: 2열
  @media (max-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: repeat(2, 1fr);
  }

  // 모바일: 1열 (태블릿 breakpoint 사용)
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: 1fr;
    gap: ${({ theme }) => theme.spacing.md};
  }
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

const Tag = styled.span`
  background: ${({ theme }) => theme.colors.background.dark};
  color: ${({ theme }) => theme.colors.text.secondary};
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  font-size: 0.8em;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  transition: all 0.2s ease;
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.accent}20;
    border-color: ${({ theme }) => theme.colors.primary.accent};
    color: ${({ theme }) => theme.colors.primary.accent};
  }
  
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 0.75em;
    padding: 3px 6px;
  }
`;

const HighlightCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.light};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  transition: ${({ theme }) => theme.animation.transition};
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    border-color: ${({ theme }) => theme.colors.border.accent};
  }

  .thumbnail {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
      height: 180px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }
  }

  &:hover img {
    transform: scale(1.1);
  }

  .content {
    padding: ${({ theme }) => theme.spacing.md};

    h3 {
      font-size: 1.1rem;
      margin-bottom: ${({ theme }) => theme.spacing.sm};
      
      @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        font-size: 1rem;
      }
    }

    .views {
      color: ${({ theme }) => theme.colors.text.secondary};
      font-size: 0.9em;
      margin-top: ${({ theme }) => theme.spacing.sm};
      display: flex;
      align-items: center;
      gap: ${({ theme }) => theme.spacing.xs};
      
      svg {
        font-size: 1.1em;
      }
    }
  }
`;

const Highlights = () => {
  const [highlights, setHighlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedHighlight, setSelectedHighlight] = useState(null);

  useEffect(() => {
    const fetchHighlights = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/highlights?populate=*`);
        const result = await response.json();
        
        // API 응답 데이터 구조에 맞게 변환
        const formattedHighlights = result.data.map(item => ({
          id: item.id,
          title: item.Title,
          documentId: item.documentId,
          tags: item.Tags,
          views: item.Views,
          youtubeUrl: item.YouTubeURL,
          // Thumbnail URL 생성
          thumbnail: item.Thumbnail?.formats?.large?.url 
	    ? `${process.env.REACT_APP_API_URL}${item.Thumbnail.formats.large.url}`
	    : `${process.env.REACT_APP_API_URL}${item.Thumbnail.url}`
        }));

        setHighlights(formattedHighlights);
      } catch (error) {
        console.error('하이라이트 데이터 로딩 실패:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchHighlights();
  }, []);

  if (loading) return <div>로딩 중...</div>;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  const cardVariants = {
    hidden: { 
      y: 50,
      opacity: 0,
      scale: 0.95
    },
    visible: (index) => ({
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        duration: 0.6,
        delay: index * 0.3 + 2.0,
        bounce: 0.4
      }
    })
  };

  return (
    <Container
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <PageTitle
        initial={{ y: -20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <RiVideoLine />
        <h1>하이라이트</h1>
      </PageTitle>
      
      <SubHeader
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ 
          duration: 0.6, 
          delay: 1,
          ease: "easeOut" 
        }}
      >
        EPL, MLB, NBA 등 모든 경기의 <strong>하이라이트</strong>를 확인하세요!
      </SubHeader>

      <HighlightsGrid>
        {highlights.map((highlight, index) => (
          <HighlightCard
            key={highlight.id}
            custom={index}
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            whileHover={{ 
              scale: 1.02,
              y: -5,
              transition: { duration: 0.2 }
            }}
            onClick={() => setSelectedHighlight(highlight)}
          >
            <div className="thumbnail">
              <img src={highlight.thumbnail} alt={highlight.title} />
            </div>
            <div className="content">
              <h3>{highlight.title}</h3>
              <TagList>
                {highlight.tags.map((tag, tagIndex) => (
                  <Tag key={tagIndex}>{tag}</Tag>
                ))}
              </TagList>
              <div className="views">
                <RiEyeLine />
                {highlight.views.toLocaleString()}
              </div>
            </div>
          </HighlightCard>
        ))}
      </HighlightsGrid>

      <PostModal 
        post={selectedHighlight} 
        onClose={() => setSelectedHighlight(null)} 
      />
    </Container>
  );
};

export default Highlights;
