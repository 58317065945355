import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.light};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: ${({ theme }) => theme.spacing.lg};
  margin: ${({ theme }) => theme.spacing.md};
  transition: ${({ theme }) => theme.animation.transition};

  &:hover {
    transform: translateY(-2px);
    border-color: ${({ theme }) => theme.colors.primary.accent};
    box-shadow: 0 4px 12px rgba(252, 163, 17, 0.1);
  }
`;

const Card = ({ children, ...props }) => {
  return <StyledCard {...props}>{children}</StyledCard>;
};

export default Card;
