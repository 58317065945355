import React, { useState } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { NavLink, useNavigate } from 'react-router-dom';
import { RiMenu3Line, RiCloseLine, RiHome4Line, RiTvLine, RiTimeLine, RiNewspaperLine, RiTeamLine, RiUserLine, RiLoginBoxLine, RiArrowRightSLine } from 'react-icons/ri';
import { NAV_ITEMS } from '../../../constants/routes';
import { useAuth } from '../../../context/AuthContext';

const Nav = styled(motion.nav)`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 250px;
  background: #0A0E17;
  z-index: 1000;

  ${({ theme }) => theme.media.mobile} {
    height: 60px;
    width: 100%;
    bottom: auto;
  }
`;

const MobileHeader = styled.div`
  display: none;
  
  ${({ theme }) => theme.media.mobile} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 ${({ theme }) => theme.spacing.md};
    background: #0A0E17;
    position: relative;
  }
`;

const MobileLogo = styled.div`
  display: none;
  
  ${({ theme }) => theme.media.mobile} {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    
    img {
      height: 30px;
      width: auto;
      filter: drop-shadow(0 0 8px ${({ theme }) => theme.colors.primary.accent}40);
      transition: ${({ theme }) => theme.animation.transition};
    }
  }
`;

const MenuButton = styled.button`
  display: none;
  
  ${({ theme }) => theme.media.mobile} {
    display: block;
    background: none;
    border: none;
    color: ${({ theme }) => theme.colors.text.primary};
    font-size: 1.5rem;
    cursor: pointer;
  }
`;

const MobileNavOverlay = styled(motion.div)`
  display: none;
  
  ${({ theme }) => theme.media.mobile} {
    display: ${({ $isOpen }) => $isOpen ? 'block' : 'none'};
    position: fixed;
    top: 60px;  // MobileHeader 높이만큼
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme }) => theme.colors.background.dark};
    z-index: 999;  // NavList보다 낮은 z-index
  }
`;

const NavList = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.lg};
  list-style: none;
  margin: 0;
  
  ${({ theme }) => theme.media.mobile} {
    display: ${({ $isOpen }) => $isOpen ? 'flex' : 'none'};
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 1000;
    background: transparent;  // 배경색 제거
    padding: ${({ theme }) => theme.spacing.md};
  }
`;

const NavItem = styled.li`
  list-style: none;
`;

const StyledNavLink = styled(NavLink)`
  font-family: 'Paperlogy-8ExtraBold', sans-serif;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  padding: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.text.primary};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  transition: ${({ theme }) => theme.animation.transition};
  
  svg {
    font-size: 1.5rem;
    transition: all 0.3s ease;
    color: ${({ theme }) => theme.colors.primary.accent};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
    color: ${({ theme }) => theme.colors.primary.accent};
    text-shadow: 0 0 5px ${({ theme }) => theme.colors.primary.accent}40;
    
    svg {
      transform: scale(1.2);
    }
  }

  &.active {
    background: ${({ theme }) => theme.colors.primary.dark};
    color: ${({ theme }) => theme.colors.primary.accent};
    text-shadow: 0 0 8px ${({ theme }) => theme.colors.primary.accent}60;
    position: relative;
    
    svg {
      filter: drop-shadow(0 0 3px ${({ theme }) => theme.colors.primary.accent});
    }
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 4px;
      background: ${({ theme }) => theme.colors.primary.accent};
      box-shadow: 0 0 8px ${({ theme }) => theme.colors.primary.accent};
    }
  }
`;

const LogoContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    max-width: 150px;
    height: auto;
    filter: drop-shadow(0 0 10px ${({ theme }) => theme.colors.primary.accent});
    transition: all 0.3s ease;
    
    &:hover {
      transform: scale(1.05);
    }
  }
  
  ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`;

const UserCardContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${({ theme }) => theme.spacing.lg};
  background: ${({ theme }) => theme.colors.background.darker};
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: ${({ theme }) => theme.animation.transition};

  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  ${({ theme }) => theme.media.mobile} {
    display: none;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.text.primary};
`;

const UserAvatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.primary.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const UserDetails = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  
  svg {
    color: ${({ theme }) => theme.colors.text.primary};
    font-size: 1.2rem;
  }
`;

const UserName = styled.span`
  font-family: 'Paperlogy-8ExtraBold', sans-serif;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleLogoClick = () => {
    navigate('/');
    setIsOpen(false);
  };

  const handleUserCardClick = () => {
    if (user) {
      navigate('/mypage');
    } else {
      navigate('/login');
    }
    setIsOpen(false);
  };

  return (
    <Nav>
      <LogoContainer onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <img src="/images/logo.png" alt="GameTime Logo" />
      </LogoContainer>
      
      <MobileHeader>
        <MenuButton onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <RiCloseLine /> : <RiMenu3Line />}
        </MenuButton>
        <MobileLogo onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
          <img src="/images/logo.png" alt="GameTime Logo" />
        </MobileLogo>
      </MobileHeader>
      
      <AnimatePresence>
        {isOpen && (
          <MobileNavOverlay
            $isOpen={isOpen}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>
      
      <NavList 
        $isOpen={isOpen}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
      >
        {NAV_ITEMS.map((item) => (
          <NavItem key={item.path}>
            <StyledNavLink 
              to={item.path}
              onClick={() => setIsOpen(false)}
            >
              <item.icon />
              {item.label}
            </StyledNavLink>
          </NavItem>
        ))}
      </NavList>
      
      <UserCardContainer onClick={handleUserCardClick}>
        <UserInfo>
          <UserAvatar>
            {user?.avatar ? (
              <img src={user.avatar} alt="프로필" />
            ) : (
              <RiUserLine />
            )}
          </UserAvatar>
          <UserDetails>
            <UserName>
              {user ? '마이페이지' : '로그인해주세요'}
            </UserName>
            <RiArrowRightSLine />
          </UserDetails>
        </UserInfo>
      </UserCardContainer>
    </Nav>
  );
};
