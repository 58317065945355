import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { RiCloseLine } from 'react-icons/ri';

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: ${({ theme }) => theme.spacing.lg};
`;

const ModalContent = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.main};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  width: 100%;
  max-width: 800px;
  height: 80vh;
  overflow-y: auto;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spacing.lg};
  right: ${({ theme }) => theme.spacing.lg};
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text.accent};
  font-size: 2rem;
  cursor: pointer;
  z-index: 1;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 1.8;
  
  h1 {
    margin-bottom: ${({ theme }) => theme.spacing.xl};
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.text.accent};
  }
  
  h2 {
    margin: ${({ theme }) => theme.spacing.xl} 0 ${({ theme }) => theme.spacing.md};
    font-size: 1.4rem;
  }
  
  p {
    margin-bottom: ${({ theme }) => theme.spacing.md};
  }
`;

const TermsModal = ({ isOpen, onClose, type }) => {
  const content = type === 'terms' ? {
    title: '이용약관',
    body: `
      <section>
            <h2>제1조 (목적)</h2>
            <p>본 약관은 회사가 제공하는 서비스를 이용함에 있어 회사와 회원 간의 제반 사항을 정함으로써 상호 권리와 의무를 명확히 하는 것을 목적으로 합니다.</p>
        </section>
        <section>
            <h2>제2조 (용어의 정의)</h2>
            <ul>
                <li>"서비스"란 회사가 운영하는 <strong>게임타임</strong> 플랫폼에서 제공되는 스포츠 실시간 중계, 데이터 콘텐츠 및 관련 부가 서비스를 의미합니다.</li>
                <li>"회원"이란 본 약관에 동의하고 서비스를 이용하는 자를 의미합니다.</li>
                <li>"콘텐츠"란 서비스에서 제공되는 모든 자료, 정보, 데이터, 텍스트, 이미지, 동영상 등을 의미합니다.</li>
                <li>"계정"이란 회원이 서비스 이용을 위해 설정한 아이디(ID)와 비밀번호를 말합니다.</li>
            </ul>
        </section>
        <section>
            <h2>제3조 (약관의 효력 및 변경)</h2>
            <ol>
                <li>본 약관은 회원이 서비스에 가입하거나 이용함으로써 효력이 발생합니다.</li>
                <li>회사는 필요한 경우 약관을 개정할 수 있으며, 변경된 약관은 서비스 내 공지사항을 통해 사전 공지합니다. 변경된 약관은 공지 시 명시된 효력 발생일부터 적용됩니다.</li>
                <li>회원은 변경된 약관에 동의하지 않을 경우 서비스 이용을 중단하고 회원 탈퇴를 요청할 수 있습니다.</li>
            </ol>
        </section>
        <section>
            <h2>제4조 (서비스 제공 및 변경)</h2>
            <ol>
                <li>회사는 회원에게 다음과 같은 서비스를 제공합니다:
                    <ul>
                        <li>스포츠 실시간 중계</li>
                        <li>경기 관련 통계 및 데이터 콘텐츠 제공</li>
                        <li>기타 스포츠 관련 부가 서비스</li>
                    </ul>
                </li>
                <li>회사는 서비스 운영상 필요한 경우, 서비스 내용을 추가, 변경, 중단할 수 있으며, 사전 공지를 원칙으로 합니다. 다만, 긴급하거나 불가피한 사유가 있는 경우 사후 공지를 할 수 있습니다.</li>
            </ol>
        </section>
        <section>
            <h2>제5조 (회원의 의무)</h2>
            <p>회원은 서비스 이용 시 관련 법령, 본 약관, 공공질서 및 미풍양속에 반하는 행위를 해서는 안 됩니다.</p>
            <p>다음 각 호의 행위를 하여서는 안 됩니다:</p>
            <ul>
                <li>타인의 계정을 도용하거나 개인정보를 무단으로 사용하는 행위</li>
                <li>회사 또는 제3자의 명예를 훼손하거나 업무를 방해하는 행위</li>
                <li>회사의 사전 승인 없이 콘텐츠를 복제, 배포, 상업적 목적으로 사용하는 행위</li>
            </ul>
        </section>
        <section>
            <h2>제6조 (회사의 책임 제한)</h2>
            <ol>
                <li>회사는 서비스 제공 중 불가항력적 상황으로 인한 서비스 중단에 대해 책임을 지지 않습니다.</li>
                <li>회사는 다음의 경우 발생하는 손해에 대해 책임을 지지 않습니다:
                    <ul>
                        <li>회원의 귀책사유로 인한 서비스 이용 장애</li>
                        <li>회사 관리 범위를 벗어난 네트워크 문제로 인한 장애</li>
                        <li>회원이 콘텐츠를 잘못 활용하여 발생한 문제</li>
                    </ul>
                </li>
            </ol>
        </section>
        <section>
            <h2>제7조 (지적재산권)</h2>
            <p>서비스에서 제공되는 모든 콘텐츠에 대한 저작권 및 지적재산권은 회사에 귀속됩니다.</p>
            <p>회원은 회사의 사전 동의 없이 콘텐츠를 무단으로 복제, 배포, 전송, 출판, 상업적 이용할 수 없습니다.</p>
        </section>
        <section>
            <h2>제8조 (계약 해지 및 이용 제한)</h2>
            <ol>
                <li>회원은 언제든지 서비스 내 설정 메뉴를 통해 계정을 탈퇴함으로써 이용계약을 해지할 수 있습니다.</li>
                <li>회사는 다음의 경우 사전 통보 없이 회원의 이용을 제한하거나 계약을 해지할 수 있습니다:
                    <ul>
                        <li>본 약관 위반 행위가 확인된 경우</li>
                        <li>부정한 방법으로 서비스를 이용한 경우</li>
                        <li>기타 회사의 서비스 운영에 심각한 지장을 초래한 경우</li>
                    </ul>
                </li>
            </ol>
        </section>
        <footer>
            <p>본 약관은 2024년 1월 1일부터 시행됩니다.</p>
            <p><strong>게임타임 운영팀</strong></p>
        </footer>
    `
  } : {
    title: '개인정보처리방침',
    body: `
      <section>
            <h2>제1조 (목적)</h2>
            <p>본 개인정보처리방침은 <strong>게임타임</strong> (이하 "회사")가 제공하는 서비스와 관련하여 회원의 개인정보를 보호하고, 처리하는 방법을 규정합니다.</p>
        </section>
        <section>
            <h2>제2조 (수집하는 개인정보 항목)</h2>
            <p>회사는 다음과 같은 개인정보를 수집할 수 있습니다:</p>
            <ul>
                <li>필수정보: 아이디, 비밀번호, 이메일 주소, 닉네임</li>
                <li>선택정보: 생년월일, 성별, 프로필 사진</li>
                <li>자동 수집 정보: IP 주소, 쿠키, 접속 로그, 기기 정보</li>
            </ul>
        </section>
        <section>
            <h2>제3조 (개인정보 수집 및 이용 목적)</h2>
            <p>회사는 수집한 개인정보를 다음의 목적을 위해 사용합니다:</p>
            <ul>
                <li>서비스 제공 및 운영: 계정 생성, 본인 확인, 중계 서비스 제공</li>
                <li>고객 관리: 문의 및 불만 처리, 공지사항 전달</li>
                <li>서비스 개선: 사용자 통계 분석 및 서비스 품질 향상</li>
                <li>법적 의무 준수: 관련 법령에 따른 의무 이행</li>
            </ul>
        </section>
        <section>
            <h2>제4조 (개인정보의 보유 및 이용기간)</h2>
            <p>회사는 법령에 따른 개인정보 보유 및 이용기간 또는 회원 동의에 따른 기간 동안 개인정보를 보유하며, 기간이 종료된 후에는 해당 정보를 지체 없이 파기합니다.</p>
            <ul>
                <li>계정 정보: 회원 탈퇴 시까지 보유</li>
                <li>법적 의무 관련 정보: 관련 법령에서 정한 기간 동안 보유</li>
            </ul>
        </section>
        <section>
            <h2>제5조 (개인정보 처리의 위탁)</h2>
            <p>회사는 서비스 운영을 위해 필요한 경우 다음과 같은 업무를 위탁할 수 있습니다:</p>
            <ul>
                <li>데이터 저장 및 관리</li>
                <li>고객 문의 대응</li>
                <li>결제 처리</li>
            </ul>
            <p>위탁 시 위탁받는 업체와 개인정보 보호 관련 의무를 명확히 규정합니다.</p>
        </section>
        <section>
            <h2>제6조 (개인정보의 파기)</h2>
            <p>회사는 개인정보 보유 기간이 경과하거나 처리 목적이 달성된 경우, 다음과 같은 절차에 따라 개인정보를 파기합니다:</p>
            <ul>
                <li>전자적 파일: 복구 불가능한 방법으로 영구 삭제</li>
                <li>종이 문서: 분쇄하거나 소각</li>
            </ul>
        </section>
        <section>
            <h2>제7조 (회원의 권리)</h2>
            <p>회원은 언제든지 다음 권리를 행사할 수 있습니다:</p>
            <ul>
                <li>개인정보 열람, 수정 및 삭제 요청</li>
                <li>동의 철회 및 계정 탈퇴</li>
            </ul>
            <p>권리 행사를 위해 고객센터를 통해 요청하시면 신속히 처리하겠습니다.</p>
        </section>
        <section>
            <h2>제8조 (쿠키의 사용)</h2>
            <p>회사는 다음과 같은 목적으로 쿠키를 사용합니다:</p>
            <ul>
                <li>사용자 맞춤형 서비스 제공</li>
                <li>접속 빈도 및 방문 시간 분석</li>
            </ul>
            <p>회원은 웹 브라우저 설정을 통해 쿠키 저장을 거부할 수 있습니다.</p>
        </section>
        <footer>
            <p>본 개인정보처리방침은 2024년 1월 1일부터 시행됩니다.</p>
            <p><strong>게임타임 운영팀</strong></p>
        </footer>
    `
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <ModalOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          <ModalContent
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            onClick={e => e.stopPropagation()}
          >
            <CloseButton onClick={onClose}>
              <RiCloseLine />
            </CloseButton>
            <Content>
              <h1>{content.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: content.body }} />
            </Content>
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
};

export default TermsModal;
