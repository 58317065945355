import { useEffect } from 'react';
import { 
  SplashContainer, 
  LogoContainer, 
  Logo, 
  LogoText,
  SubTitle,
  LoadingBar,
  LoadingProgress
} from './Splash.styles';

const Splash = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      document.querySelector('.loading-progress').style.width = '100%';
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <SplashContainer>
      <LogoContainer>
        <Logo>
          <LogoText className="neon-text">GAME</LogoText>
          <LogoText className="neon-text accent">TIME</LogoText>
        </Logo>
        <SubTitle>실시간 글로벌 스포츠 중계 No.1</SubTitle>
        <LoadingBar>
          <LoadingProgress className="loading-progress" />
        </LoadingBar>
      </LogoContainer>
    </SplashContainer>
  );
};

export default Splash;