import { useState, useEffect } from 'react';
import Loading from '../../common/Loading';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { RiShieldStarLine } from 'react-icons/ri';
import axios from 'axios';

const Container = styled(motion.div)`
  padding: ${({ theme }) => theme.spacing.lg};
`;

const PageTitle = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  h1 {
    margin: 0;
    font-size: 1.8rem;
  }

  svg {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const SubHeader = styled(motion.p)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  max-width: 800px;

  strong {
    color: ${({ theme }) => theme.colors.primary.accent};
    font-weight: bold;
  }
`;

const SiteGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
  margin-top: ${({ theme }) => theme.spacing.xl};
  max-width: 800px;
  width: 100%;
`;

const SiteCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.light};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  transition: ${({ theme }) => theme.animation.transition};
  width: 100%;
  overflow: hidden;

  .site-header {
    display: block;
    position: relative;
    margin-bottom: ${({ theme }) => theme.spacing.lg};

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0)
      );
      pointer-events: none;
    }

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    h3 {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: ${({ theme }) => theme.spacing.lg};
      color: white;
      font-size: 1.5rem;
      margin: 0;
      z-index: 1;
    }
  }

  .site-content {
    padding: ${({ theme }) => theme.spacing.lg};
  }

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 24px rgba(252, 163, 17, 0.25);
    border-color: ${({ theme }) => theme.colors.border.accent};

    .site-header img {
      transform: scale(1.05);
    }
  }

  .site-info {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.sm};
    margin-bottom: ${({ theme }) => theme.spacing.md};
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: 1rem;

    span {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }

  .verification-badge {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    background: ${({ theme }) => theme.colors.primary.accent};
    color: ${({ theme }) => theme.colors.primary.white};
    padding: 8px 16px;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    font-size: 0.9rem;
    font-weight: bold;
    box-shadow: 0 2px 8px rgba(252, 163, 17, 0.2);
    cursor: pointer;
    border: none;
    transition: all 0.2s ease;

    &:hover {
      background: ${({ theme }) => theme.colors.primary.dark};
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(252, 163, 17, 0.3);
    }

    &:active {
      transform: translateY(0);
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    .site-header {
      img {
        height: 160px;
      }
      
      h3 {
        font-size: 1.3rem;
        padding: ${({ theme }) => theme.spacing.md};
      }
    }
  }
`;

const statusMap = {
  active: "🟢 정상운영중",
  maintenance: "🟡 점검중",
  closed: "🔴 운영종료"
};

const SafeSites = () => {
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadSafeSites = async () => {
      try {
        const BASE_URL = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${BASE_URL}/api/safe-sites`, {
          params: {
            populate: '*'
          }
        });

        console.log('API Response:', response.data);

        if (!response.data.data) {
          throw new Error('데이터 형식이 올바르지 않습니다.');
        }

        const data = response.data.data.map(site => {
          const imageUrl = site.thumbnail?.url 
            ? `${BASE_URL}${site.thumbnail.url}`
            : '/images/site-placeholder.png';
            
          return {
            id: site.id,
            documentId: site.documentId,
            name: site.name,
            rating: site.rating,
            info: site.info,
            signupCode: site.signupCode,
            status: site.sitestatus,
            url: site.url,
            thumbnail: imageUrl,
            createdAt: new Date(site.createdAt).toLocaleDateString()
          };
        });

        setSites(data);
      } catch (err) {
        setError('안전사이트 목록을 불러오는데 실패했습니다.');
        console.error('API Error:', err);
      } finally {
        setLoading(false);
      }
    };

    loadSafeSites();
  }, []);

  if (loading) return <Loading />;
  if (error) return <div>{error}</div>;

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <PageTitle
        initial={{ y: -20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <RiShieldStarLine />
        <h1>안전 사이트</h1>
      </PageTitle>

      <SubHeader
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 1, ease: "easeOut" }}
      >
        게임타임이 보증하는 무사고 <strong>안전 놀이터 !</strong> 
      </SubHeader>

      <SiteGrid>
        {sites.map((site, index) => (
          <SiteCard
            key={site.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              duration: 0.4,
              delay: index * 0.5 + 1.5,
              ease: "easeOut"
            }}
          >
            <div className="site-header">
              <img src={site.thumbnail} alt={site.name} />
              <h3>{site.name}</h3>
            </div>
            <div className="site-content">
              <div className="site-info">
                <span>⭐ {site.rating}</span>
                <span>👥 {site.info}</span>
                <span>✅ 가입코드 : {site.signupCode}</span>
                <span>{statusMap[site.status]}</span>
              </div>
              <button 
                className="verification-badge"
                onClick={() => window.open(site.url, '_blank')}
              >
                <span>🔗 사이트 바로가기</span>
              </button>
            </div>
          </SiteCard>
        ))}
      </SiteGrid>
    </Container>
  );
};

export default SafeSites;

