export const theme = {
  colors: {
    primary: {
      main: '#1a1f2c',    // 진한 다크 블루
      dark: '#0f1219',    // 더 진한 다크 블루
      light: '#2a3142',   // 밝은 다크 블루
      accent: '#fca311',  // 오렌지 (강조색)
      white: '#ffffff'    // 흰색
    },
    
    background: {
      dark: '#0f1219',
      main: '#1a1f2c',
      light: '#2a3142',
    },
    
    text: {
      primary: '#ffffff',
      secondary: '#9ca3af',
      accent: '#fca311',
      disabled: '#4b5563'
    },
    
    border: {
      main: '#2a3142',
      light: '#374151',
      accent: '#fca311'
    }
  },
  
  breakpoints: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
    wide: '1440px'
  },
  
  media: {
    mobile: `@media (max-width: 767px)`,
    tablet: `@media (min-width: 768px) and (max-width: 1023px)`,
    desktop: `@media (min-width: 1024px)`,
    wide: `@media (min-width: 1440px)`
  },
  
  layout: {
    sidebarWidth: '250px',
    headerHeight: '60px',
    maxWidth: '1200px'
  },
  
  spacing: {
    xs: '0.25rem',
    sm: '0.5rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '2rem'
  },
  
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '12px',
    round: '50%'
  },
  
  animation: {
    transition: 'all 0.3s ease'
  }
};

export const globalKeyframes = `
  @keyframes neonPulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.6; }
  }

  @keyframes rainbowFlow {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  @keyframes borderFlow {
    0% { border-color: #ff1e1e; }
    20% { border-color: #ffff00; }
    40% { border-color: #00ff88; }
    60% { border-color: #00ffff; }
    80% { border-color: #4d00ff; }
    100% { border-color: #ff1e1e; }
  }

  @keyframes colorChange {
    0% { color: #ff1e1e; }
    20% { color: #ffff00; }
    40% { color: #00ff88; }
    60% { color: #00ffff; }
    80% { color: #4d00ff; }
    100% { color: #ff1e1e; }
  }

  @keyframes glowSpin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
`;
