import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { RiBarChartLine, RiEyeLine, RiThumbUpLine } from 'react-icons/ri';
import PostModal from '../../common/PostModal/PostModal';

const Container = styled(motion.div)`
  padding: ${({ theme }) => theme.spacing.lg};
`;

const PageTitle = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.md};

  h1 {
    margin: 0;
  }

  svg {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.primary.accent};
  }

  ${({ theme }) => theme.media.mobile} {
    h1 {
      font-size: 1.5rem;
    }
    
    svg {
      font-size: 1.5rem;
    }
  }
`;

const SubHeader = styled(motion.p)`
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 1.1rem;
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  strong {
    color: ${({ theme }) => theme.colors.primary.accent};
    font-weight: bold;
  }

  ${({ theme }) => theme.media.mobile} {
    font-size: 1rem;
    margin-bottom: ${({ theme }) => theme.spacing.lg};
  }
`;

const MatchGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.spacing.md};
  
  ${({ theme }) => theme.media.tablet} {
    grid-template-columns: 1fr;
  }
`;

const MatchCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.light};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  padding: ${({ theme }) => theme.spacing.lg};
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.md};
  text-align: center;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const TeamSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) => theme.spacing.lg};
`;

const TeamInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  min-width: 120px;  // 최소 너비 설정으로 이름이 잘리지 않도록
  
  ${({ theme }) => theme.media.mobile} {
    min-width: auto;
    gap: ${({ theme }) => theme.spacing.xs};
  }
`;

const TeamLogo = styled.img`
  width: 48px;
  height: 48px;
  object-fit: contain;

  ${({ theme }) => theme.media.mobile} {
    width: 24px;
    height: 24px;
  }
`;

const TeamName = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.xs};  // 로고와의 간격 추가

  ${({ theme }) => theme.media.tablet} {
    font-size: 1rem;  // 태블릿에서는 중간 크기
  }

  ${({ theme }) => theme.media.mobile} {
    font-size: 0.85rem;  // 모바일은 기존 크기 유지
  }
`;

const VS = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary.accent};
`;

const MatchInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};
  flex: 1;
`;

const DateLeague = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.8rem;
`;

const Stats = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.8rem;
  
  span {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
  }
`;

const SportsAnalysis = () => {
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadMatches = async () => {
      setLoading(true);
      try {
        const [matchResponse, teamsResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/api/match-analysises?populate=*`),
          fetch(`${process.env.REACT_APP_API_URL}/api/soccer-teams?populate=teamlogo`)
        ]);

        const matchData = await matchResponse.json();
        const teamsData = await teamsResponse.json();

        const teams = {};
        teamsData.data.forEach(team => {
          teams[team.teamname] = {
            name: team.teamname,
            logo: team.teamlogo?.url 
              ? `${process.env.REACT_APP_API_URL}${team.teamlogo.url}`
              : '/images/team-logo-placeholder.png'
          };
        });

        const formattedMatches = matchData.data.map(match => {
          const [homeTeamName, awayTeamName] = match.title.split(' vs ');
          
          return {
            id: match.id,
            title: match.title,
            date: match.date 
              ? new Date(match.date).toLocaleDateString('ko-KR', {
                  month: 'numeric',
                  day: 'numeric',
                  weekday: 'short'
                })
              : 'No Date',
            league: match.league || 'Unknown League',
            views: match.views || 0,
            likes: match.likes || 0,
            content: match.content || '',
            homeTeam: {
              name: homeTeamName,
              logo: teams[homeTeamName]?.logo || '/images/team-logo-placeholder.png'
            },
            awayTeam: {
              name: awayTeamName,
              logo: teams[awayTeamName]?.logo || '/images/team-logo-placeholder.png'
            }
          };
        });

        console.log('Formatted matches:', formattedMatches);
        setMatches(formattedMatches);
      } catch (error) {
        console.error('매치 분석 데이터 로딩 실패:', error);
      } finally {
        setLoading(false);
      }
    };

    loadMatches();
  }, []);

  const handleMatchClick = (match) => {
    const modalData = {
      ...match,
      type: 'match',
      title: `${match.homeTeam.name} vs ${match.awayTeam.name} 경기 분석`,
      date: match.date,
      views: match.views,
      likes: match.likes,
      content: match.content,
      homeTeam: {
        name: match.homeTeam.name,
        logo: match.homeTeam.logo
      },
      awayTeam: {
        name: match.awayTeam.name,
        logo: match.awayTeam.logo
      },
      league: match.league
    };
    setSelectedMatch(modalData);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  const titleVariants = {
    hidden: { y: -50, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.8,
        bounce: 0.4
      }
    }
  };

  const subHeaderVariants = {
    hidden: { x: -50, opacity: 0 },
    visible: { 
      x: 0, 
      opacity: 1,
      transition: {
        type: "spring",
        duration: 0.8,
        delay: 0.3,
        bounce: 0.4
      }
    }
  };

  const cardVariants = {
    hidden: { 
      y: 50,
      opacity: 0,
      scale: 0.95
    },
    visible: (index) => ({
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        duration: 0.6,
        delay: 0.5 + (index * 0.1),
        bounce: 0.4
      }
    })
  };

  return (
    <Container
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <PageTitle
        variants={titleVariants}
        initial="hidden"
        animate="visible"
      >
        <RiBarChartLine />
        <h1>스포츠 분석</h1>
      </PageTitle>
      
      <SubHeader
        variants={subHeaderVariants}
        initial="hidden"
        animate="visible"
      >
        실시간 <strong>스포츠 분석</strong>을 확인하세요!
      </SubHeader>

      {loading ? (
        <div>로딩 중...</div>
      ) : (
        <MatchGrid>
          {matches.map((match, index) => (
            <MatchCard
              key={match.id}
              custom={index}
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              whileHover={{ 
                scale: 1.02,
                y: -5,
                transition: { duration: 0.2 }
              }}
              onClick={() => handleMatchClick(match)}
            >
              <TeamSection>
                <TeamInfo>
                  <TeamLogo src={match.homeTeam.logo} alt={match.homeTeam.name} />
                  <TeamName>{match.homeTeam.name}</TeamName>
                </TeamInfo>
                <MatchInfo>
                  <DateLeague>
                    <div>{match.date}</div>
                    <div>{match.league}</div>
                  </DateLeague>
                  <VS>VS</VS>
                </MatchInfo>
                <TeamInfo>
                  <TeamLogo src={match.awayTeam.logo} alt={match.awayTeam.name} />
                  <TeamName>{match.awayTeam.name}</TeamName>
                </TeamInfo>
              </TeamSection>

              <Stats>
                <span><RiEyeLine />{match.views}</span>
                <span><RiThumbUpLine />{match.likes}</span>
              </Stats>
            </MatchCard>
          ))}
        </MatchGrid>
      )}

      <PostModal 
        post={selectedMatch} 
        onClose={() => setSelectedMatch(null)} 
      />
    </Container>
  );
};

export default SportsAnalysis;
