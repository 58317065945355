import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  padding: ${({ theme }) => theme.spacing.sm};
  background: ${({ theme }) => theme.colors.background.dark};
  min-height: 100vh;
`;

export const StreamCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.light};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  overflow: hidden;
  position: relative;
  height: calc(100vh - ${({ theme }) => theme.spacing.sm} * 2);
`;

export const StreamSelect = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  overflow-x: auto;
  padding-bottom: ${({ theme }) => theme.spacing.sm};
  
  button {
    padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
    background: transparent;
    border: 2px solid ${({ theme }) => theme.colors.primary.accent};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    color: ${({ theme }) => theme.colors.text.primary};
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;
    
    &:hover {
      background: ${({ theme }) => theme.colors.primary.dark};
      border-color: ${({ theme }) => theme.colors.primary.accent};
    }
    
    &.active {
      background: ${({ theme }) => theme.colors.primary.accent};
      color: ${({ theme }) => theme.colors.primary.dark};
      box-shadow: 0 0 10px ${({ theme }) => theme.colors.primary.accent};
    }
  }
`;
