import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { RiCloseLine, RiThumbUpFill, RiEyeFill, RiTimeFill, RiUserFill } from 'react-icons/ri';

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  padding: ${({ theme }) => theme.spacing.lg};
`;

const ModalContent = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.main};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  width: 100%;
  max-width: 1200px;
  height: 80vh;
  overflow-y: auto;
  position: relative;
  margin-left: 250px;
  
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spacing.lg};
  right: ${({ theme }) => theme.spacing.lg};
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.text.accent};
  font-size: 2rem;
  cursor: pointer;
  z-index: 1;

  &:hover {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const TeamHeader = styled.div`
  padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.lg};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.xl};
  background: ${({ theme }) => theme.colors.background.dark};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
  
  @media (max-width: 768px) {
    padding: ${({ theme }) => theme.spacing.lg};
    gap: ${({ theme }) => theme.spacing.md};
  }
`;

const TeamInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  width: 120px;
  
  @media (max-width: 768px) {
    width: 80px;
  }
`;

const TeamLogo = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  
  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;

const TeamName = styled.span`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text.primary};
  text-align: center;
  word-break: keep-all;
  white-space: pre-wrap;
  line-height: 1.2;
  
  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const VS = styled.span`
  font-size: 1.8rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text.accent};
  margin: ${({ theme }) => theme.spacing.sm} 0;
  
  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const MatchInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: 0 ${({ theme }) => theme.spacing.md};
  min-width: 120px;
  
  @media (max-width: 768px) {
    min-width: 80px;
    padding: 0 ${({ theme }) => theme.spacing.sm};
  }
`;

const MatchDate = styled.span`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.text.accent};
  font-weight: bold;
`;

const LeagueInfo = styled.span`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const PostContent = styled.div`
  padding: ${({ theme }) => theme.spacing.xl} 120px;
  
  @media (max-width: 1200px) {
    padding: ${({ theme }) => theme.spacing.xl} 80px;
  }
  
  @media (max-width: 768px) {
    padding: ${({ theme }) => theme.spacing.lg} ${({ theme }) => theme.spacing.xl};
  }
`;

const PostTitle = styled.h2`
  margin: 0 0 ${({ theme }) => theme.spacing.md};
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const PostMeta = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.text.secondary};
  font-size: 0.9rem;
  
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
    font-size: 0.8rem;
  }
`;

const MetaItem = styled.span`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
  white-space: nowrap;
`;

const PostBody = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
  line-height: 1.8;
  font-size: 1.2rem;

  // CKEditor 컨텐츠를 위한 추가 스타일
  .ck-editor__editable {
    background: transparent !important;
    color: ${({ theme }) => theme.colors.text.primary} !important;
  }

  // CKEditor 5의 기본 텍스트 블록
  .ck-editor__editable p {
    color: ${({ theme }) => theme.colors.text.primary} !important;
    background: transparent !important;
    margin-bottom: 1rem !important;
  }

  // CKEditor 5의 인라인 텍스트
  [data-cke-filler], 
  [class*="ck-"], 
  [contenteditable="true"] * {
    color: ${({ theme }) => theme.colors.text.primary} !important;
    background: transparent !important;
  }

  // 모든 인라인 스타일 오버라이드
  [style*="color"],
  [style*="background"] {
    color: ${({ theme }) => theme.colors.text.primary} !important;
    background: transparent !important;
  }

  // 이미지 관련 스타일
  img {
    max-width: 100%;
    height: auto;
    margin: 1rem 0;
    border-radius: ${({ theme }) => theme.borderRadius.medium};
  }

  figure {
    margin: 1rem 0;
    text-align: center;
  }

  figcaption {
    color: ${({ theme }) => theme.colors.text.secondary};
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }

  // 텍스트 스타일
  p {
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.text.primary};
  }

  strong {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text.accent};
  }

  em {
    font-style: italic;
    color: ${({ theme }) => theme.colors.text.secondary};
  }

  // 링크 스타일
  a {
    color: ${({ theme }) => theme.colors.text.accent};
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 비율 */
  height: 0;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const HighlightHeader = styled.div`
  padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.lg};
  background: ${({ theme }) => theme.colors.background.dark};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const HighlightTitle = styled.h2`
  margin: 0 0 ${({ theme }) => theme.spacing.md};
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xs};
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

const Tag = styled.span`
  background: ${({ theme }) => theme.colors.background.light};
  color: ${({ theme }) => theme.colors.text.secondary};
  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  font-size: 0.8em;
`;

const PostModal = ({ post, onClose }) => {
  if (!post) return null;

  console.log('Modal Post Data:', post); // 데이터 확인용

  // YouTube URL에서 비디오 ID 추출하는 함수
  const getYouTubeVideoId = (url) => {
    if (!url) return null;
    try {
      const urlParams = new URLSearchParams(new URL(url).search);
      return urlParams.get('v');
    } catch (error) {
      console.error('Invalid YouTube URL:', error);
      return null;
    }
  };

  // 컨텐츠 타입 구분
  const isHighlight = Boolean(post.youtubeUrl);
  const isMatch = post.type === 'match';

  // 이미지 URL을 올바르게 설정
  const imageUrl = post.thumbnail?.url 
    ? `${process.env.REACT_APP_API_URL}${post.thumbnail.url}` 
    : null;

  return (
    <AnimatePresence>
      <ModalOverlay
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={onClose}
      >
        <ModalContent
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          onClick={e => e.stopPropagation()}
        >
          <CloseButton onClick={onClose}>
            <RiCloseLine />
          </CloseButton>

          {isHighlight ? (
            // 하이라이트 모달 내용
            <>
              <HighlightHeader>
                <HighlightTitle>{post.title}</HighlightTitle>
                <TagList>
                  {post.tags?.map((tag, index) => (
                    <Tag key={index}>{tag}</Tag>
                  ))}
                </TagList>
              </HighlightHeader>
              <PostContent>
                <VideoContainer>
                  <iframe
                    src={`https://www.youtube.com/embed/${getYouTubeVideoId(post.youtubeUrl)}`}
                    title={post.title}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </VideoContainer>
                <PostMeta>
                  <MetaItem>
                    <RiEyeFill />
                    조회수 {post.views?.toLocaleString()}
                  </MetaItem>
                </PostMeta>
              </PostContent>
            </>
          ) : isMatch ? (
            // 매치 분석 모달 내용
            <>
              <TeamHeader>
                <TeamInfo>
                  <TeamLogo src={post.homeTeam.logo} alt={post.homeTeam.name} />
                  <TeamName>{post.homeTeam.name}</TeamName>
                </TeamInfo>
                <MatchInfo>
                  <MatchDate>{post.date}</MatchDate>
                  <LeagueInfo>{post.league}</LeagueInfo>
                  <VS>VS</VS>
                </MatchInfo>
                <TeamInfo>
                  <TeamLogo src={post.awayTeam.logo} alt={post.awayTeam.name} />
                  <TeamName>{post.awayTeam.name}</TeamName>
                </TeamInfo>
              </TeamHeader>
              <PostContent>
                <PostBody 
                  className="ck-content"  // CKEditor 컨텐츠임을 명시
                  dangerouslySetInnerHTML={{ 
                    __html: post.content || '내용이 없습니다.' // post.contents 제거
                  }} 
                />
              </PostContent>
            </>
          ) : (
            <>
              <NewsHeader>
                {imageUrl && (
                  <NewsImage 
                    src={imageUrl} 
                    alt={post.title} 
                  />
                )}
                <NewsTitle>{post.title}</NewsTitle>
                <NewsMeta>
                  <MetaItem>
                    <RiTimeFill />
                    {post.date}
                  </MetaItem>
                  <MetaItem>
                    <RiEyeFill />
                    조회 {post.views?.toLocaleString()}
                  </MetaItem>
                  <MetaItem>
                    <RiThumbUpFill />
                    추천 {post.likes?.toLocaleString()}
                  </MetaItem>
                </NewsMeta>
              </NewsHeader>
              <PostContent>
                <PostBody 
                  className="ck-content"  // CKEditor 컨텐츠임을 명시
                  dangerouslySetInnerHTML={{ 
                    __html: post.content || '내용이 없습니다.' // post.contents 제거
                  }} 
                />
              </PostContent>
            </>
          )}
        </ModalContent>
      </ModalOverlay>
    </AnimatePresence>
  );
};

const NewsHeader = styled.div`
  padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.lg};
  background: ${({ theme }) => theme.colors.background.dark};
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const NewsImage = styled.img`
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

const NewsTitle = styled.h2`
  margin: 0 0 ${({ theme }) => theme.spacing.md};
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const NewsMeta = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export default PostModal;
