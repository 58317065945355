import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout/Layout';
import Home from './components/pages/Home/Home';
import LiveTV from './components/pages/LiveTV/LiveTV';
import LineupInfo from './components/pages/LineupInfo/LineupInfo';
import News from './components/pages/News/News';
import Events from './components/pages/Events/Events';
import SafeSites from './components/pages/SafeSites/SafeSites';
import { Splash } from './components/common/Splash';
import Highlights from './components/pages/Highlights/Highlights';
import SportsAnalysis from './components/pages/SportsAnalysis/SportsAnalysis';
import { AuthProvider } from './context/AuthContext';
import Login from './components/pages/Login/Login';
import Register from './components/pages/Register/Register';
import ForgotPassword from './components/pages/ForgotPassword/ForgotPassword';
import MyPage from './components/pages/MyPage/MyPage';

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Splash />;
  }

  return (
    <AuthProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/live-tv" element={<LiveTV />} />
          <Route path="/lineup-info" element={<LineupInfo />} />
          <Route path="/news" element={<News />} />
          <Route path="/events" element={<Events />} />
          <Route path="/safe-sites" element={<SafeSites />} />
          <Route path="/highlights" element={<Highlights />} />
          <Route path="/sports-analysis" element={<SportsAnalysis />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/mypage" element={<MyPage />} />
        </Routes>
      </Layout>
    </AuthProvider>
  );
};

export default App;
