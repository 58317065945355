import {
  RiHome4Line,
  RiLiveLine,
  RiTimer2Line,
  RiExchangeLine,
  RiFileChartLine,
  RiNewspaperLine,
  RiGiftLine,
  RiShieldStarLine,
  RiVideoLine,
  RiBarChartLine
} from 'react-icons/ri';

export const ROUTES = {
  HOME: '/',
  LIVE_TV: '/live-tv',
  LINEUP_INFO: '/lineup-info',
  NEWS: '/news',
  EVENTS: '/events',
  SAFE_SITES: '/safe-sites',
  HIGHLIGHTS: '/highlights',
  SPORTS_ANALYSIS: '/sports-analysis'
};

export const NAV_ITEMS = [
  {
    path: ROUTES.HOME,
    label: '홈',
    icon: RiHome4Line
  },
  {
    path: ROUTES.LIVE_TV,
    label: '실시간 중계',
    icon: RiLiveLine
  },
  {
    path: ROUTES.HIGHLIGHTS,
    label: '하이라이트',
    icon: RiVideoLine
  },
  {
    path: ROUTES.SPORTS_ANALYSIS,
    label: '스포츠 분석',
    icon: RiBarChartLine
  },
  {
    path: ROUTES.LINEUP_INFO,
    label: '경기 데이터',
    icon: RiFileChartLine
  },
  {
    path: ROUTES.NEWS,
    label: '스포츠 뉴스',
    icon: RiNewspaperLine
  },
  {
    path: ROUTES.EVENTS,
    label: '이벤트',
    icon: RiGiftLine
  },
  {
    path: ROUTES.SAFE_SITES,
    label: '안전 놀이터',
    icon: RiShieldStarLine
  }
];
