import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Navigation } from '../Navigation/Navigation';

const LayoutContainer = styled.div`
  min-height: 100vh;
  display: flex;
  overflow-x: hidden;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

const MainContent = styled(motion.main)`
  flex: 1;
  padding: ${({ theme }) => theme.spacing.lg};
  margin-left: 250px;
  background: ${({ theme }) => theme.colors.background.dark};
  min-height: 100vh;
  width: calc(100% - 250px);

  ${({ theme }) => theme.media.mobile} {
    margin-left: 0;
    width: 100%;
    margin-top: 60px;
    padding: ${({ theme }) => theme.spacing.md};
    min-height: calc(100vh - 60px);
  }
`;

const Layout = ({ children }) => {
  return (
    <LayoutContainer>
      <Navigation />
      <MainContent
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </MainContent>
    </LayoutContainer>
  );
};

export default Layout;
