import { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { RiLockUnlockLine } from 'react-icons/ri';

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 120px);
  padding: ${({ theme }) => theme.spacing.lg};
`;

const LoginCard = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.light};
  border-radius: ${({ theme }) => theme.borderRadius.large};
  padding: ${({ theme }) => theme.spacing.xl};
  width: 100%;
  max-width: 400px;
  border: 1px solid ${({ theme }) => theme.colors.border.main};
`;

const Title = styled.h1`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.xl};
  color: ${({ theme }) => theme.colors.text.primary};
  
  svg {
    color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`;

const Input = styled.input`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.background.dark};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  color: ${({ theme }) => theme.colors.text.primary};
  font-size: 1rem;
  
  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary.accent};
  }
`;

const Button = styled.button`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.md};
  background: ${({ theme }) => theme.colors.primary.accent};
  color: white;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
    transform: translateY(-2px);
  }
  
  &:active {
    transform: translateY(0);
  }
`;

const Message = styled.div`
  text-align: center;
  padding: ${({ theme }) => theme.spacing.md};
  margin-top: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  
  ${({ type, theme }) =>
    type === 'success'
      ? `
    background: ${theme.colors.success + '20' || '#52c41a20'};
    color: ${theme.colors.success || '#52c41a'};
    `
      : type === 'error'
      ? `
    background: ${theme.colors.error + '20' || '#ff4d4f20'};
    color: ${theme.colors.error || '#ff4d4f'};
    `
      : ''};
`;

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ type: '', message: '' });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus({ type: '', message: '' });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setStatus({
          type: 'success',
          message: '비밀번호 재설정 링크가 이메일로 전송되었습니다. 이메일을 확인해주세요.',
        });
        setEmail('');
      } else {
        throw new Error(data.error?.message || '비밀번호 재설정 요청에 실패했습니다.');
      }
    } catch (error) {
      setStatus({
        type: 'error',
        message: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <LoginCard
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <Title>
          <RiLockUnlockLine />
          비밀번호 찾기
        </Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder="가입시 등록한 이메일"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Button type="submit" disabled={isLoading}>
            {isLoading ? '처리중...' : '비밀번호 재설정 메일 발송'}
          </Button>
        </Form>
        {status.message && (
          <Message type={status.type}>{status.message}</Message>
        )}
      </LoginCard>
    </Container>
  );
};

export default ForgotPassword;
