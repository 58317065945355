import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
  padding: ${({ theme }) => theme.spacing.sm};
  background: ${({ theme }) => theme.colors.background.dark};
  min-height: 100vh;
`;

export const TabsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  
  @media screen and (max-width: 768px) {
    gap: ${({ theme }) => theme.spacing.sm};
    margin-bottom: ${({ theme }) => theme.spacing.sm};
  }
`;

export const Tab = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 8px;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  background: ${({ theme }) => theme.colors.background.light};
  color: ${({ theme }) => theme.colors.text.primary};
  border: 1px solid ${({ theme }) => theme.colors.border.main};
  font-family: inherit;
  font-weight: 600;
  white-space: normal;
  word-break: keep-all;
  
  svg {
    margin-right: 4px;
    font-size: 1.2em;
  }
  
  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
  }
  
  &.active {
    background: ${({ theme }) => theme.colors.primary.accent};
    color: ${({ theme }) => theme.colors.background.dark};
    box-shadow: 0 0 10px ${({ theme }) => theme.colors.primary.accent};
    font-weight: 700;
    
    svg {
      color: ${({ theme }) => theme.colors.background.dark};
    }
  }
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    font-size: 12px;
    gap: 4px;
    
    svg {
      margin: 0;
      font-size: 18px;
    }
  }
`;

export const ContentCard = styled(motion.div)`
  width: 100%;
  background: ${({ theme }) => theme.colors.background.light};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  overflow: hidden;
  position: relative;
  height: calc(100vh - ${({ theme }) => theme.spacing.sm} * 4 - 60px);
  margin-top: ${({ theme }) => theme.spacing.md};
`;

const StyledIframeContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  margin-top: 0;
  position: relative;
`;

export const IframeContainer = ({ url }) => {
  return (
    <StyledIframeContainer>
      <StyledIframe
        src={url}
        title="content"
        allowFullScreen
        scrolling="yes"
        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        style={{ 
          transform: 'translateY(0)',
          display: 'block'
        }}
      />
    </StyledIframeContainer>
  );
};
