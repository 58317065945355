import { motion } from 'framer-motion';
import { IframeContainer } from '../../common/IframeContainer';
import { Container, StreamCard } from './LiveTV.styles';

const LiveTV = () => {
  const streamUrl = 'https://corestream.tv/core_dark/core.php?id=base';

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <StreamCard
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <IframeContainer url={streamUrl} />
      </StreamCard>
    </Container>
  );
};

export default LiveTV;